import { StorageManager } from "../../app/services/storage";
import { CustomHeaders } from "../../types";
// import {baseURL} from "tinymce";

const baseHeaders: CustomHeaders = {
    "X-Requested-With": "XMLHttpRequest",
    "Genelead-Client": "admin",
};

export const getCustomHeaders = (headers: Headers, withToken: boolean): Headers => {
    Object.keys(baseHeaders).forEach((key) => {
        headers.set(key, baseHeaders[key]);
    });

    if (withToken) {
        headers.set("Genelead-Token", StorageManager.get() as string);
    }

    return headers;
}

const ApiUrls = {
    login: `/`,
    forgot: `/forgot`,
    logout: `/api/me/logout`,
    refresh: (token: string) => `/refresh-password/${token}.html`,
    me: `/api/me`,
    landing: {
        list: `/api/landings`,
        liveList: (id:string) => `/api/landings/domain/${id}`,
        landing: (landing:number) => `/api/landings/${landing}`,
        customers: (landing:number|string) => `/api/landings/${landing}/customers`,
    },
    users: {
        list: "/api/users",
        edit: (user:number) => `/api/users/${user}`,
    },
    linkKeys: {
        list: "/api/link-keys/list",
        new: "/api/link-keys/new",
        edit: (identifier:string) => `/api/link-keys/${identifier}`,
        delete: (identifier:string) => `/api/link-keys/${identifier}`,
    },
    settings: 'api/setting',
    captainVerify: "/api/captain-verify",
    categories:{
        list:"/api/categories",
        edit: (category:number) => `/api/categories/${category}`,
    },
    front: {
        media: (id:string, extension:string) => process.env.REACT_APP_API_DOMAIN + `/media/raw/${id}.${extension}`,
        database: (accessToken:string) => process.env.REACT_APP_API_DOMAIN + `/databases/${accessToken}.html`,
    },
    medias: {
        list: "/api/landing-medias",
        update: (id:string|number) => `/api/landing-medias/${id}`,
    },
    sites: {
        list: "/api/sites",
        edit: (site:number) => `/api/sites/${site}`,
    },
    databases: {
        list: "/api/email-databases",
        edit: (db:number) => `/api/email-databases/${db}`,
        upload: (db:string) => `/api/email-databases/${db}/upload`,
    },
    companies: {
        list: "/api/companies",
        newCustomer: (company:string|number) => `/api/companies/${company}/accounts`,
        deleteCustomer: (company:string|number, account:string|number) => `/api/companies/${company}/accounts/${account}`,
        editCustomer: (company:string|number, account:string|number) => `/api/companies/${company}/accounts/${account}`,
        customerFormGateway: (company:string|number, customerForm:string|number, gateway:string|number|'') => `/api/companies/${company}/forms/${customerForm}/gateways/${gateway}`,
        customerFormGatewayList: (company:string|number, customerForm:string|number) => `/api/companies/${company}/forms/${customerForm}/gateways`,
        // customExportCodes: (company) => `/api/companies/${company}/custom-export-codes`,
        downloadEmails: (company:string|number, token:string) => `/api/companies/${company}/emails?access_token=${token}`,
        downloadAllEmails: (company:string|number, database:string|null, token:string) => `/api/companies/${company}/filter/${database}/emails?access_token=${token}`,
        editCompany: (company:string|number) => `/api/companies/${company}`,
        deleteCompany: (company:string|number) => `/api/companies/${company}`,
        enableCompany: (company:string|number) => `/api/companies/${company}/enable`,
        enableCustomer: (company:string|number, account:string|number) => `/api/companies/${company}/enable/${account}`,
        refreshLeads: (company:string|number, form: string|number, gateway: string|number) => `/api/companies/${company}/forms/${form}/gateways/${gateway}/reprocess`,
    },
    leads: {
        list: "/api/leads",
        exports: (type:string) => `/api/leads/exports/${type}`,
        downloads: (format:string, token:string) => `/api/leads/download/export.${format}?access_token=${token}`,
        periodSummary: "/api/leads/period-summary",
    },
    /** @todo */
    list: {
        forms: "/api/list/forms",
        companies: "/api/list/companies",
        categories: "/api/list/categories",
        countries: "/api/list/countries",
        customers: "/api/list/customers",
        gateways: "/api/list/gateways",
        fieldConverters: "/api/list/field-converters",
        campaigns: "/api/list/campaigns",
        databases: "/api/list/databases",
    },
    fields: {
        list: "/api/fields",
        edit: (id:number) => `/api/fields/${id}`,
    },
    forms: {
        list: "/api/forms",
        edit: (form:string) => `/api/forms/${form}`,
        register: (form:string) => `/api/forms/${form}/register`,
    },
    coreg: {
        list: "/api/coreg",
        edit: (coreg:string) => `/api/coreg/${coreg}`,
    },
    fbforms: {
        list: "/api/admin/fbforms",
        synchro: (form:string) => `/api/admin/fbforms/${form}/synchro`,
        install: "/api/admin/fbforms/install",
        searchPages: `/api/admin/fbforms/search/pages`,
        searchPageForms: (page:string) => `/api/admin/fbforms/search/pages/${page}/forms`,
    },
    reporting: '/api/dashboard/reporting',
    preferences: {
        get: "/api/user-preferences",
        create: "/api/user-preferences/new",
        manage: (id: string) => `/api/user-preferences/${id}`,
    },
    gateway: {
        list: "/api/gateways",
        view: "/api/customer-forms/gateways",
        sendingblue: {
            emailLists: (apiKey:string) => `/api/gateways/sendinblue/email-lists/${apiKey}`,
        },
    },
    templates: '/api/templates',
}

export default ApiUrls;