import { useEffect, useState } from "react"
import { Accordion, InputGroup } from "react-bootstrap"
import { Typeahead } from "react-bootstrap-typeahead"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import globalApi from "../../app/services/secured/global"
import manageApi from "../../app/services/secured/manage"
import { modalReportingTemplateShow } from "../../app/slice/modal"
import AppSecured from "../../AppSecured"
import { PageHeader } from "../App/PageHeader"
import { LoadingBlock } from "../misc/Blocks"
import { ButtonIcon } from "../misc/Buttons"
import DatePicker, { formatDateForRequest } from "../misc/DatePicker"
import { FilterSelect } from "../misc/Filters"
import {
  Dimension,
  getReportingParams,
  RData,
  ReportingQuery,
  resetParams,
  RStat,
  setParams,
  setReportingData
} from "./reporting"
import ReportingChart from "./ReportingChart"
import ReportingOptions from "./ReportingOptions"
import ReportingTable from "./Components/Table";

const ReportingList = () => {
  const dispatch = useAppDispatch();

  const [ query, setQuery ] = useState<ReportingQuery>({
    from: formatDateForRequest(new Date()),
    to: formatDateForRequest(new Date())
  });
  const reporting = manageApi.useGetReportingQuery(query);
  const params = useAppSelector(getReportingParams);

  useEffect(() => {
    if ( reporting.isSuccess ) {
      dispatch(setReportingData(reporting.data));
    }
  }, [ reporting ])

  const templates = manageApi.useGetReportingTemplatesQuery();
  const companies = globalApi.useGetCompaniesListQuery();
  const [ selectedCompanies, setSelectedCompanies ] = useState<Array<any>>([]);
  const [ sTemplate, setSTemplate ] = useState({ name: '', id: '' });

  const [ manualDate, setManualDate ] = useState({ from: '', to: '' });

  const onTemplateSelect = ( e: Record<string, number> ) => {
    if ( templates.data && companies.data ) {
      const tmplt = templates.data[ e.templateIndex ];
      if ( tmplt ) {
        setSTemplate({ name: tmplt.homeDataTable.title, id: tmplt.id ? tmplt.id : '' });
        let newP = { ...params };

        let search = { ...newP.search };
        Object.entries(search).map(p => {
          search[ p[ 0 ] as Dimension ] = {
            ...search[ p[ 0 ] as Dimension ],
            active: tmplt.homeDataTable.search.aggregations.findIndex(t => t === p[ 0 ]) > -1 ? true : false
          }
        });
        newP = { ...newP, search };

        let stats = { ...newP.stats };
        Object.entries(stats).map(p => {
          stats[ p[ 0 ] as RStat ] = {
            ...stats[ p[ 0 ] as RStat ],
            active: tmplt.homeDataTable.search.filters.findIndex(t => t === p[ 0 ]) > -1 ? true : false
          };
        });
        newP = { ...newP, stats };

        let data = { ...newP.data };
        Object.entries(newP.data).map(p => {
          data[ p[ 0 ] as RData ] = {
            ...data[ p[ 0 ] as RData ],
            active: tmplt.homeDataTable.search.filters.findIndex(t => t === p[ 0 ]) > -1 ? true : false
          };
        });
        newP = { ...newP, data };

        const selectedCompanies = companies.data.filter(c => tmplt.homeDataTable.search.company_id.findIndex(cc => parseInt(cc) === c.id) > -1);
        const queryCompanies = selectedCompanies.map(c => c.id).join(',').toString();

        let qFrom: string, qTo: string;
        if ( !tmplt.homeDataTable.search.relative_period ) {
          qFrom = tmplt.homeDataTable.search.from;
          qTo = tmplt.homeDataTable.search.to;
          setManualDate({ from: tmplt.homeDataTable.search.from, to: tmplt.homeDataTable.search.to });
        } else {
          const from = formatDateForRequest(new Date(new Date().setDate(new Date().getDate() + tmplt.homeDataTable.search.from_days)));
          const to = formatDateForRequest(new Date(new Date().setDate(new Date().getDate() + tmplt.homeDataTable.search.to_days)));
          setManualDate({ from, to });
          qFrom = from;
          qTo = to;
        }

        setSelectedCompanies(selectedCompanies.map(c => {
          return { name: c.name, id: c.id }
        }));
        dispatch(setParams(newP));
        setQuery({ ...query, company_id: queryCompanies, from: qFrom, to: qTo });
      } else {
        setSTemplate({ name: '', id: '' });
        const range = { from: formatDateForRequest(new Date()), to: formatDateForRequest(new Date()) };
        setManualDate(range);
        setSelectedCompanies([]);
        dispatch(resetParams());
        setQuery(range);
      }
    }
  }

  return <>
    <AppSecured>
      <PageHeader action={
        <ButtonIcon
          code={"arrow-clockwise"} size={"sm"} variant={"primary"} className='alt-primary'
          onClick={() => dispatch(manageApi.util.invalidateTags([ 'Reporting' ]))}>Rafraîchir
          données</ButtonIcon>
      }>
        <DatePicker manual={manualDate} onChange={( from: string, to: string ) => setQuery({ ...query, from, to })}/>
        <div>
          <div className='d-flex ms-5'>
            <InputGroup>
              <FilterSelect title='Rapports personnalisés' name='templateIndex' onSelectChange={onTemplateSelect}>
                {templates.data?.map(( t, i ) => <option key={i} value={i}>{t.homeDataTable.title}</option>)}
              </FilterSelect>

              <ButtonIcon code={"bookmark-star"} size={"sm"} variant={"secondary"} className='alt-primary'
                          onClick={() => dispatch(modalReportingTemplateShow({
                            from: query.from,
                            to: query.to,
                            company_id: selectedCompanies.map(c => c.id.toString()),
                            template: { id: sTemplate.id, name: sTemplate.name }
                          }))}
              >
                {sTemplate.id ? 'Éditer' : 'Créer'}
              </ButtonIcon>
            </InputGroup>
          </div>
        </div>
      </PageHeader>

      <div className='mb-2'>
        {companies.isSuccess && companies.data && <>
            <Typeahead
                id='customer-choice'
                multiple
                selected={selectedCompanies}
                placeholder='Liste des clients'
                labelKey={'name'}
                options={companies.data.map(c => {
                  return { name: c.name, id: c.id }
                })}
                onChange={( selection ) => {
                  if ( selection.length ) {
                    setSelectedCompanies(selection);
                    const selectCompanies: Array<string> = [];
                    selection.map(( s: any ) => {
                      const opt = s as { name: string, id: number };
                      selectCompanies.push(opt.id.toString());
                    })
                    setQuery({ ...query, company_id: selectCompanies.join(',').toString() });
                  } else {
                    setSelectedCompanies([]);
                    setQuery({ ...query, company_id: '' });
                  }
                }}
            />
        </>}
      </div>

      <Accordion>
        <Accordion.Item eventKey='0'>
          <Accordion.Header>
            Options
          </Accordion.Header>
          <Accordion.Body>
            <ReportingOptions/>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey='1'>
          <Accordion.Header>Graphique</Accordion.Header>
          <Accordion.Body>
            <LoadingBlock data={reporting}>
              <ReportingChart/>
            </LoadingBlock>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <LoadingBlock data={reporting}>
        <ReportingTable/>
      </LoadingBlock>
    </AppSecured>
  </>
}

export default ReportingList;