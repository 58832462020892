import ApiUrls from "../../../assets/js/ApiUrls";
import { jsonToFormData } from "../../../components/misc/SimpleForm";
import { ReportingQuery } from "../../../components/Reporting/reporting";
import {
    Company,
    CompanyAccountParams,
    Customer,
    FormGateway,
    GatewayAPI,
    GatewayView,
    HomeDataTable,
    Lead,
    LeadExport,
    LeadExportSearch,
    LeadExportWaiting,
    LeadSearch,
    ListData,
    ReportingTemplateAPI,
    Sendinblue_API,
    WebserviceView
} from "../../../types";
import securedApi from "./securedApi";

const manageApi = securedApi.injectEndpoints({
    endpoints: (build) => ({

        // Companies
        getCompanies: build.query<ListData<Company>, any>({
            query: (params) => ({
                url: ApiUrls.companies.list,
                params: params,
            }),
            providesTags: ['Companies'],
        }),
        getCompany: build.query<Company, Pick<Company, 'id'>>({
            query: (params) => ApiUrls.companies.editCompany(params.id),
            providesTags: ['Company']
        }),
        updateCompany: build.mutation<Company, any>({
            query: (params) => ({
                url: ApiUrls.companies.editCompany(params.company_id),
                method: 'POST',
                body: jsonToFormData(params.data),
            }),
            invalidatesTags: ['Company']
        }),
        deleteCompany: build.mutation<any, Pick<Company, 'id'>>({
            query: (params) => ({
                url: ApiUrls.companies.deleteCompany(params.id),
                method:'DELETE',
            }),
            invalidatesTags: ['Companies']
        }),
        enableCompany: build.query<any, Pick<Company, 'id'>>({
            query: (params) => ({
                url: ApiUrls.companies.enableCompany(params.id),
            })
        }),
        deleteAccount: build.mutation<any, {company: Pick<Company, 'id'>, account: Pick<Customer, 'id'>}>({
            query: (params) => ({
                url: ApiUrls.companies.deleteCustomer(params.company.id, params.account.id),
                method: 'DELETE',
            }),
            invalidatesTags: ['Company']
        }),
        enableAccount: build.query<any, {company: Pick<Company, 'id'>, account: Pick<Customer, 'id'>}>({
            query: (params) => ({
                url: ApiUrls.companies.enableCustomer(params.company.id, params.account.id),
            })
        }),
        refreshLeads: build.mutation<any, {company: string|number, form: string|number, gateway: string|number, data: {from: string}}>({
            query: (params) => ({
                url: ApiUrls.companies.refreshLeads(params.company, params.form, params.gateway),
                method: 'POST',
                body: jsonToFormData(params.data)
            })
        }),
        
        // Companies - Gateways
        getFormGateways: build.query<Array<FormGateway>, {company:string|number, form:string|number}>({
            query: (params) => ApiUrls.companies.customerFormGatewayList(params.company, params.form),
            providesTags: ['FormGateways'],
        }),
        getFormGateway: build.query<Array<FormGateway>, {company:string|number, form:string|number, gateway:string|number}>({
            query: (params) => ApiUrls.companies.customerFormGateway(params.company, params.form, params.gateway),
            providesTags: ['FormGateways']
        }),
        getSendInBlueList: build.query<Sendinblue_API, {apiToken: string}>({
            query: (params) => ApiUrls.gateway.sendingblue.emailLists(params.apiToken)
        }),
        handleFormGateway: build.mutation<any, GatewayAPI>({
            query: (params) => ({
                url: ApiUrls.companies.customerFormGateway(params.company, params.form, params.gateway ? params.gateway : ''),
                method: 'POST',
                body: jsonToFormData(params.data),
            }),
            invalidatesTags: ['FormGateways']
        }),
        getWebservices: build.query<Array<WebserviceView>, any>({
            query: (params) => ({
                url: ApiUrls.gateway.view,
                params: params
            }),
            transformResponse: (r:Array<GatewayView>) => {
                const newRes:Array<WebserviceView> = [];
                
                r.map((g:GatewayView) => {
                    const gateway = newRes.findIndex(c => g.gateway_id === c.gateway_id);
                    if (gateway > -1) {
                        return newRes[gateway].webservice.push(g);
                    } else {
                        return newRes.push({
                            gateway_id: g.gateway_id,
                            gateway: g.gateway,
                            webservice: [g]
                        })
                    }
                })

                return newRes;
            }
        }),

        // Companies - Accounts
        getAccount: build.query<Customer, {company_id: string|number, account_id: string|number}>({
            query: (params) => ApiUrls.companies.editCustomer(params.company_id, params.account_id),
            transformResponse: (r: Customer) => {
                const newC = {...r};
                newC.forms.sort((a, b) => b.id - a.id);
                return newC;
            },
        }),
        updateCompanyAccount: build.mutation<Customer, CompanyAccountParams>({
            query: (params) => ({
                url: ApiUrls.companies.editCustomer(params.company.id, params.account.id),
                method: 'POST',
                body: jsonToFormData(params.data)
            }),
            invalidatesTags: ['FormGateways']
        }),
        createAccount: build.mutation<any, {company:Pick<Company, 'id'>} & {data:Pick<Customer, 'admin'|'firstName'|'lastName'|'email'|'phoneNumber'>}>({
            query: (params) => ({
                url: ApiUrls.companies.newCustomer(params.company.id),
                method:'POST',
                body: jsonToFormData(params.data)
            }),
            invalidatesTags: ['Company']
        }),

        // leads
        getLeads: build.query<ListData<Lead>, LeadSearch>({
            query: (search) => ({
                url: ApiUrls.leads.list,
                params: search
            })
        }),
        getExportLeads: build.query<ListData<LeadExport>, LeadExportSearch>({
            query: (search) => ({
                url: ApiUrls.leads.exports('exported'),
                params: search
            })
        }),
        getLeadsWaiting: build.query<Array<LeadExportWaiting>, void>({
            query: () => ({
                url: ApiUrls.leads.exports('pending'),
            }),
        }),
        getGateways: build.query<Array<{id: number, name: string}>, void>({
            query: () => ApiUrls.list.gateways,
        }),

        // Reporting
        getReporting: build.query<any, ReportingQuery>({
            query: (params) => ({
                url: ApiUrls.reporting,
                params: params,
            }),
            providesTags: ['Reporting'],
        }),
        getReportingTemplates: build.query<ReportingTemplateAPI, void>({
            query: () => ApiUrls.preferences.get,
            providesTags: ['ReportingTemplates']
        }),
        createTemplate: build.mutation<any, {home_data_table: HomeDataTable}>({
            query: (args) => ({
                url: ApiUrls.preferences.create,
                method: 'POST',
                body: args
            }),
            invalidatesTags: ['ReportingTemplates']
        }),
        handleTemplate: build.mutation<any, {home_data_table: HomeDataTable} & {id: string}>({
            query: (args) => ({
                url: ApiUrls.preferences.manage(args.id),
                method: 'POST',
                body: args
            }),
            invalidatesTags: ['ReportingTemplates']
        }),
        deleteTemplate: build.mutation<any, {home_data_table: HomeDataTable} & {id: string}>({
            query: (args) => ({
                url: ApiUrls.preferences.manage(args.id),
                method: 'DELETE',
            }),
            invalidatesTags: ['ReportingTemplates']
        })
    })
})

export default manageApi;